import { useEffect, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import useParticipants from '../useParticipants/useParticipants';
import useSessionContext from '../useSessionContext/useSessionContext';

export default function useAgentInTheRoomWatcher() {
  const { room } = useVideoContext();
  const { isCustomer } = useSessionContext();
  const participants = useParticipants();
  const [countParticipants, setCountParticipants] = useState(0);


  useEffect(() => {
    if (!isCustomer) {
      return;
    }
    if (countParticipants > 0 && participants.length === 0 && room.state === 'connected') {
      room.disconnect();
    }
    setCountParticipants(participants.length);
  }, [participants, room, countParticipants, isCustomer]);

  return participants;
}
