import { Server } from '../../server';
import { ApiBaseResponse } from '../../types';
import { LoginResponse, OTPVerifyResponse } from './auth-types';

const login = async (email: string, password: string): Promise<LoginResponse> => {
  const { token, error, user } = await Server.agentLogin(email, password);
  return { token, error, user };
};

const verifyOneTimePasscode = async (code: string): Promise<OTPVerifyResponse> => {
  return await Server.verifyOTP(code);
};

const resendOTP = async (): Promise<ApiBaseResponse> => {
  return await Server.resendOTP();
};

const resetPasswordRequest = async (email: string): Promise<any> => {
  return await Server.resetPasswordRequest(email);
};

const setNewPasswordRequest = async (perishableToken: string, newPassword): Promise<ApiBaseResponse> => {
  return await Server.setNewPasswordRequest(perishableToken, newPassword);
};

const validateNewPasswordTokenRequest = async (perishableToken: string): Promise<ApiBaseResponse> => {
  return await Server.validateNewPasswordToken(perishableToken);
};

export {
  login,
  verifyOneTimePasscode,
  resetPasswordRequest,
  setNewPasswordRequest,
  validateNewPasswordTokenRequest,
  resendOTP,
};
