import React, { ChangeEvent } from 'react';
import { makeStyles, TextField, Grid, Button, Theme } from '@material-ui/core';
import { isEmpty, trim } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // display: 'grid',
    justifyContent: 'space-between',
    gridAutoRows: '1fr 1fr 1fr',
    margin: '1.5em 0 1.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    margin: '5px 5px',
  },
  label: {
    backgroundColor: 'white',
    color: '#5f6368',
    fontWeight: 100,
  },
}));

interface CustomerDetailsScreenProps {
  name: string;
  setName: (name: string) => void;
  onNewMeeting: () => void;
  onGenerateMeetingLink: () => void;
}

export default function CustomerDetailsScreen({
  name,
  setName,
  onNewMeeting,
  onGenerateMeetingLink,
}: CustomerDetailsScreenProps) {
  const classes = useStyles();
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  function canSubmitNewMeeting(): boolean {
    return !isEmpty(trim(name));
  }

  const startButtonTitle = 'NEW MEETING';

  return (
    <>
      {/*<form onSubmit={handleSubmit}>*/}
      <div className={classes.inputContainer}>
        <div className={classes.textFieldContainer}>
          <TextField
            InputLabelProps={{
              classes: {
                root: classes.label,
              },
            }}
            id="input-user-name"
            variant="outlined"
            fullWidth
            label="Customer name"
            size="small"
            value={name}
            onChange={handleNameChange}
          />
        </div>
      </div>
      <Grid container justify="flex-end">
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={!canSubmitNewMeeting()}
          className={classes.continueButton}
          onClick={onGenerateMeetingLink}
        >
          GENERATE MEETING LINK
        </Button>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={!canSubmitNewMeeting()}
          className={classes.continueButton}
          onClick={onNewMeeting}
        >
          {startButtonTitle}
        </Button>
      </Grid>
      {/*</form>*/}
    </>
  );
}
