import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { isEmpty, isNil, trim, get } from 'lodash';
import { useHistory } from 'react-router-dom';
import querystring from 'querystring';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // display: 'grid',
    justifyContent: 'space-between',
    gridAutoRows: '1fr 1fr 1fr',
    margin: '1.5em 0 1.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  heading: {
    marginBottom: '0.4em',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  label: {
    backgroundColor: 'white',
    color: '#5f6368',
    fontWeight: 100,
  },
}));

interface JoinMeetingByIDProps {
  showTitle: boolean;
}

export default function JoinMeetingByID({ showTitle }: JoinMeetingByIDProps) {
  const classes = useStyles();
  const history = useHistory();
  const [meetingID, setMeetingID] = useState<string>('');
  const handleMeetingIDChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMeetingID(event.target.value);
  };

  // console.log('JoinMeetingByID', meetingID);

  function canSubmitJoinMeeting(): boolean {
    return !isEmpty(trim(meetingID));
  }

  const handleJoinMeetingSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let meetingId: any = meetingID;
    if (meetingId.toLowerCase().startsWith('http')) {
      const paramsString = get(meetingId.split('?'), '[1]');
      const pageQueryParams = querystring.parse(paramsString);
      const { stk } = pageQueryParams;
      if (!isNil(stk)) {
        meetingId = stk;
      } else {
        const fetchMeetingID = /.*\/(.*)$/;
        const matches = meetingID.match(fetchMeetingID);
        meetingId = matches && matches.length > 1 && matches[1];
      }
    }

    history.push(`/conference/session/${meetingId}`);
  };

  return (
    <>
      {showTitle && (
        <Typography variant="h4" className={classes.heading}>
          Join A Meeting
        </Typography>
      )}
      <form onSubmit={handleJoinMeetingSubmit}>
        <div className={classes.inputContainer}>
          <div className={classes.textFieldContainer}>
            <TextField
              InputLabelProps={{
                classes: {
                  root: classes.label,
                },
              }}
              key="meetingId"
              id="input-meeting-name"
              variant="outlined"
              label="Meeting ID"
              margin="normal"
              fullWidth
              size="small"
              value={meetingID}
              onChange={handleMeetingIDChange}
            />
          </div>
        </div>
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!canSubmitJoinMeeting()}
            className={classes.continueButton}
          >
            JOIN
          </Button>
        </Grid>
      </form>
    </>
  );
}
