import React from 'react';
import ParticipantList from '../ParticipantList/ParticipantList';
import { styled } from '@material-ui/core/styles';
import MainParticipant from '../MainParticipant/MainParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import DualScreenParticipant from '../DualScreenParticipant/DualScreenParticipant';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import { isMobile } from '../../utils';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useAgentInTheRoomWatcher from '../../hooks/useAgentInTheRoomWatcher/useAgentInTheRoomWatcher';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
  gridTemplateRows: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: `100%`,
    gridTemplateRows: `1fr ${theme.sidebarMobileHeight + 16}px`,
  },
}));

const DualScreenContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: `100%`,
    gridTemplateRows: `1fr ${theme.sidebarMobileHeight + 16}px`,
  },
}));

export default function Room() {
  const participants = useParticipants();
  const mainParticipant = useMainParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  useAgentInTheRoomWatcher();
  const isShowDualScreen = !screenShareParticipant && participants && participants.length === 1 && !isMobile;

  const {
    room: { localParticipant },
  } = useVideoContext();
  return (
    <>
      {isShowDualScreen &&
      <DualScreenContainer>
        <DualScreenParticipant dualScreenParticipant={mainParticipant} />
        <DualScreenParticipant dualScreenParticipant={localParticipant} videoOnly={true}/>
      </DualScreenContainer>
      }
      {!isShowDualScreen &&
      <Container>
        <MainParticipant />
        <ParticipantList />
      </Container>
      }
    </>
  );
}
