import { useAppState } from '../state';
import useConnectionOptions from '../utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from '../components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { VideoProvider } from '../components/VideoProvider';
import ErrorDialog from '../components/ErrorDialog/ErrorDialog';
import CustomerConferencePage from '../components/CustomerConferencePage/CustomerConferencePage';
import React from 'react';

const CustomerVideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <UnsupportedBrowserWarning>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <CustomerConferencePage />
      </VideoProvider>
    </UnsupportedBrowserWarning>
  );
};

export default CustomerVideoApp;
