import React, { ChangeEvent, useState, FormEvent, useEffect } from 'react';
import { useAppState } from '../../state';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: 'white',
    color: 'rgb(0, 94, 166)',
    borderRadius: '4px',
    border: '2px solid rgb(2, 122, 197)',
    margin: '1.8em 0 0.7em',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.3em 1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  inputFieldContainer: {
    minHeight: '120px',
    width: '100%',
  },
  submitButtonContainer: {
    padding: '15px 0px',
  },
  inputField: {
    width: '100%',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function NewPasswordPage() {
  const classes = useStyles();
  const { setNewPasswordRequestForAgent, validateNewPasswordToken } = useAppState();
  const history = useHistory();
  // const location = useLocation<{ from: Location }>();
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [requestSent, setRequestSent] = useState(false);
  const [tokenError, setTokenError] = useState<Error | null>(null);

  useEffect(() => {
    if (token) {
      return;
    }
    const match = window.location.search.match(/token=(.*)(&.*)/);
    const _token = get(match, '[1]');
    setToken(_token);
    validateNewPasswordToken(_token).then(data => {
      const { error } = data;
      if (error) {
        setTokenError(error);
      }
    });
  }, [setToken, token, validateNewPasswordToken])

  const setNewPasswordRequest = () => {
    setTokenError(null);
    if (!token) {
      return;
    }
    setNewPasswordRequestForAgent(token, newPassword)
      .then(() => {
        setRequestSent(true);
        setTimeout(() => {
          history.replace({ pathname: '/' });
        }, 3000);
      })
      .catch(err => {
        console.error('resetPasswordRequest::ERROR', err);
        setTokenError(err);
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setNewPasswordRequest();
  };

  // if (!isAuthReady) {
  //   return null;
  // }

  function isSubmitDisabled() {
    return password !== newPassword || !password || password.length < 6;
  }

  return (
    <IntroContainer>
      <div>
        {tokenError && (
          <Typography variant="caption" className={classes.errorMessage}>
            <ErrorOutlineIcon />
            {tokenError.message}
          </Typography>
        )}
      </div>
      {requestSent &&
      <Typography variant="h5" className={classes.gutterBottom}>
        Password Reset Requested
      </Typography>
      }
      {!requestSent && !tokenError &&
      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          Enter new password
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container justify="space-between">
            <div className={classes.inputFieldContainer}>
              <InputLabel shrink htmlFor="input-email">
                New Password
              </InputLabel>
              <TextField
                id="input-password"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                type="password"
                variant="outlined"
                size="small"
                autoComplete="password"
                className={classes.inputField}
              />
              <PasswordStrengthBar password={password} />
              <InputLabel shrink htmlFor="input-email">
                Repeat New Password
              </InputLabel>
              <TextField
                id="input-repeat-password"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
                type="password"
                variant="outlined"
                size="small"
                autoComplete="password"
                className={classes.inputField}
              />
              <div>
                {/*{tokenError && (*/}
                {/*  <Typography variant="caption" className={classes.errorMessage}>*/}
                {/*    <ErrorOutlineIcon />*/}
                {/*    {tokenError.message}*/}
                {/*  </Typography>*/}
                {/*)}*/}
              </div>
            </div>
          </Grid>
          <Grid container justify="flex-end" className={classes.submitButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitDisabled()}
              className={classes.submitButton}
            >
              Reset
            </Button>
          </Grid>
        </form>
      </>
      }
    </IntroContainer>
  );
}
