import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/styles/makeStyles';
import Person from '@material-ui/icons/Person';
import { StateContextType, useAppState } from '../../../../state';
import { LiveUIConfig } from '../../../../apis/conference/conference-types';
import { Theme } from '@material-ui/core';

const useStyles = (liveUI?: LiveUIConfig) => {
  return makeStyles((theme: Theme) => ({
    avatarBack: {
      color: 'white',
      backgroundColor: liveUI?.primaryColor || '#F22F46',
    },
  }))();
};

export function getInitials(name: string) {
  return name
    .split(' ')
    .map(text => text[0])
    .join('')
    .toUpperCase();
}

export default function UserAvatar({ user }: { user: StateContextType['user'] }) {
  const { brandConfig } = useAppState();
  const classes = useStyles(brandConfig.liveUI);
  const { name, photoURL } = user!;

  return photoURL ? (
    <Avatar src={photoURL} />
  ) : (
    <Avatar className={classes.avatarBack}>{name ? getInitials(name || '') : <Person />}</Avatar>
  );
}
