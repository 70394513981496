import React, { useEffect } from 'react';
import Snackbar from '../Snackbar/Snackbar';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useLogActivities from '../../state/useLogActivities/useLogActivities';
import { UserActivityType } from '../../types';
import { isEmpty } from 'lodash';
export default function ReconnectingNotification() {
  const roomState = useRoomState();

  const { logUserActivity } = useLogActivities();

  const toActivityEvent = (roomState: string): any => {
    switch (roomState) {
      case 'reconnected':
        return UserActivityType.RoomReconnected;
      case 'reconnecting':
        return UserActivityType.RoomReconnecting;

    }
  };
  useEffect(() => {
    if (roomState) {
      const event = toActivityEvent(roomState);
      if (!isEmpty(event)) {
        logUserActivity(event).then();
      }
    }
  }, [logUserActivity, roomState]);

  return (
    <Snackbar
      variant="error"
      headline="Connection Lost:"
      message="Reconnecting to room..."
      open={roomState === 'reconnecting'}
    />
  );
}
