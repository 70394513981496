import { useCallback } from 'react';
import { ActivityContext, UserActivityType } from '../../types';
import { StorageWrapper } from '../../storage-wrapper';
import { logActivity } from '../../apis/activity/activity-api';

export function getErrorMessage(message: string) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

export default function useLogActivities() {

  const logUserActivity = useCallback(
    (action: UserActivityType, context?: ActivityContext) => {
      const customer = StorageWrapper.getCustomerModel();
      const { userUid = '', sessionToken } = customer || {};
      const sessionId = StorageWrapper.getCurrentSessionId() || sessionToken;

      return logActivity(action, sessionId, userUid, context)
        .then(res => {
          return res;
        });
    },
    []
  );


  return {
    logUserActivity,
  };
}
