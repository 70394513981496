import React, { useState, useEffect } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import PreflightTest from './PreflightTest/PreflightTest';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Video from 'twilio-video';
import CustomerDetailsScreen from './CustomerDetailsScreen/CustomerDetailsScreen';
import useSessionContext from '../../hooks/useSessionContext/useSessionContext';
import { useParams } from 'react-router-dom';
import { isNil, isEmpty } from 'lodash';
import JoinMeetingByID from './JoinMeetingByID/JoinMeetingByID';
import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { makeStyles, Theme } from '@material-ui/core';
import GenerateMeetingUrl from './GenerateMeetingUrl/GenerateMeetingUrl';

export enum Steps {
  roomNameOrMeetingIdStep,
  deviceSelectionStep,
  generateMeetingLink,
}

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // display: 'grid',
    justifyContent: 'space-between',
    gridAutoRows: '1fr 1fr 1fr',
    margin: '1.5em 0 1.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  divider: {
    marginTop: '30px',
  },
}));

export interface PreJoinScreensProps {
  initialStep?: Steps;
}

export default function PreJoinScreens() {
  const classes = useStyles();
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const history = useHistory();
  const { token: sessionToken } = useParams();
  const [step, setStep] = useState(Steps.roomNameOrMeetingIdStep);
  const [name, setName] = useState<string>('');
  const [isAutomaticConnectionToSession, setIsAutomaticConnectionToSession] = useState<boolean>(false);
  // const isAutomaticConnectionToSession = !isNil(sessionToken) && !isEmpty(sessionToken);
  const { setCustomer } = useSessionContext();
  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (!isNil(sessionToken) && !isEmpty(sessionToken)) {
      setIsAutomaticConnectionToSession(true);
    }
  }, [sessionToken, step]);

  useEffect(() => {
    if (isAutomaticConnectionToSession) {
      setStep(Steps.deviceSelectionStep);
    }
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, isAutomaticConnectionToSession, mediaError]);

  const onNewMeeting = () => {
    // event.preventDefault();
    setCustomer({ name });
    setStep(Steps.deviceSelectionStep);
  };

  const onGenerateMeetingLink = () => {
    setCustomer({ name });
    setStep(Steps.generateMeetingLink);
  };

  const cancelAction = () => {
    history.push('/');
    setStep(Steps.roomNameOrMeetingIdStep);
  };

  const SubContent = (
    <>
      {Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );
  return (
    <IntroContainer subContent={step === Steps.deviceSelectionStep && SubContent}>
      {step === Steps.roomNameOrMeetingIdStep && (
        <>
          <CustomerDetailsScreen
            name={name}
            setName={setName}
            onNewMeeting={onNewMeeting}
            onGenerateMeetingLink={onGenerateMeetingLink}
          />
          <Divider className={classes.divider} light />
          <JoinMeetingByID showTitle={false} />
        </>
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={user?.name} setStep={setStep} cancelAction={cancelAction} />
      )}

      {step === Steps.generateMeetingLink && <GenerateMeetingUrl cancelAction={cancelAction} />}
    </IntroContainer>
  );
}
