import React, { ChangeEvent, useState, FormEvent } from 'react';
import { useAppState } from '../../state';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  inputFieldContainer: {
    minHeight: '120px',
    width: '100%',
  },
  submitButtonContainer: {
    padding: '15px 0px',
  },
  inputField: {
    width: '100%',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function ResetPasswordRequestPage() {
  const classes = useStyles();
  const { resetPasswordRequestForAgent } = useAppState();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [requestSent, setRequestSent] = useState(false);
  const [authError, setAuthError] = useState<Error | null>(null);


  const resetPasswordRequest = () => {
    setAuthError(null);
    resetPasswordRequestForAgent?.(email)
      .then(() => {
        setRequestSent(true);
        setTimeout(() => {
          history.replace({ pathname: '/login' });
        }, 3000);
      })
      .catch(err => {
        console.error('resetPasswordRequest::ERROR', err);
        setAuthError(err);
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetPasswordRequest();
  };

  // if (!isAuthReady) {
  //   return null;
  // }

  return (
    <IntroContainer>
      {requestSent &&
        <Typography variant="h5" className={classes.gutterBottom}>
          Email was sent to the requested email address.

          Please check your inbox.
        </Typography>
      }
      {!requestSent &&
      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          Enter email to reset password for
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container justify="space-between">
            <div className={classes.inputFieldContainer}>
              <InputLabel shrink htmlFor="input-email">
                Email
              </InputLabel>
              <TextField
                id="input-email"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                autoComplete="username"
                className={classes.inputField}
              />
              <div>
                {authError && (
                  <Typography variant="caption" className={classes.errorMessage}>
                    <ErrorOutlineIcon />
                    {authError.message}
                  </Typography>
                )}
              </div>
            </div>
          </Grid>
          <Grid container justify="flex-end" className={classes.submitButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!email.length || requestSent}
              className={classes.submitButton}
            >
              Send
            </Button>
          </Grid>
        </form>
      </>
      }
    </IntroContainer>
  );
}
