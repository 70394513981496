import { Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from '../MenuBar/Menu/Menu';
import { useAppState } from '../../state';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: 'white',
    paddingLeft: '1em',
    display: 'none',
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  endCallButton: {
    height: '28px',
    fontSize: '0.85rem',
    padding: '0 0.6em',
  },
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      height: '28px',
      minWidth: '28px',
      border: '1px solid rgb(136, 140, 142)',
      padding: 0,
      margin: '0 1em',
    },
  },
  logo: {
    maxWidth: 151,
    maxHeight: 50
  },
}));

export default function MobileTopMenuBar() {
  const classes = useStyles();
  const { brandConfig } = useAppState();

  return (
    <Grid container alignItems="center" justify="space-between" className={classes.container}>
      {/*<Typography variant="subtitle1">{room.name}</Typography>*/}
      <Grid style={{ flex: 1 }}>
        <img
          className={classes.logo}
          src={brandConfig.brandWebLogo?.url}
          alt={brandConfig.brandName}
        />
      </Grid>
      <div>
        <EndCallButton className={classes.endCallButton} />
        <Menu buttonClassName={classes.settingsButton} />
      </div>
    </Grid>
  );
}
