import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isNil } from 'lodash';
import Button from '@material-ui/core/Button';
import { ReactComponent as LinkIcon } from './link_icon.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles, Theme } from '@material-ui/core';
import useSessionContext from '../../../hooks/useSessionContext/useSessionContext';
import { useAppState } from '../../../state';
import { UserActivityType } from '../../../types';
import useLogActivities from '../../../state/useLogActivities/useLogActivities';

const useStyles = makeStyles((theme: Theme) => ({
  linkIcon: {
    width: '15px',
    height: '15px',
    marginRight: '10px',
  }
}));

export default function CopySessionUrlButton(props: { disabled?: boolean; className?: string }) {
  const { customer: customerDetails } = useSessionContext();
  const { brandConfig } = useAppState();
  const { logUserActivity } = useLogActivities();
  const lastClickTimeRef = useRef(0);
  const [isCopyingSessionUrl, setIsCopyingSessionUrl] = useState(false);
  const [successIconColor, setSuccessIconColor] = useState('#000000');
  const classes = useStyles();

  const copyToClipboard = async (str?: string) => {
    if (isNil(str)) {
      return;
    }
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (!isNil(navigator.clipboard)) {
      await navigator.clipboard.writeText(str);
    }

  };

  const copyUrl = useCallback(async () => {
    if (Date.now() - lastClickTimeRef.current > 200) {
      lastClickTimeRef.current = Date.now();
      setIsCopyingSessionUrl(true);
      const sessionUrl = customerDetails?.sessionUrl;
      await copyToClipboard(sessionUrl);
      await logUserActivity(UserActivityType.CopyInvite, { sessionUrl });
      setTimeout(() => {
        setIsCopyingSessionUrl(false);
      }, 3000);
    }
  }, [customerDetails, logUserActivity]);

  useEffect(() => {
    setSuccessIconColor(brandConfig.liveUI?.primaryColor || '#000000');
  }, [brandConfig.liveUI]);
  return (
    <Button
      className={props.className}
      onClick={copyUrl}
      // disabled={!hasVideoDevices || props.disabled}
    >
      {isCopyingSessionUrl ? <CheckCircleIcon style={{ color: successIconColor }} /> : <LinkIcon className={classes.linkIcon}/> } Copy Invite
    </Button>
  );
}
