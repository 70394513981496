import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppState } from '../../state';

export default function PrivateRoute({ children, path, ...rest }: RouteProps) {
  const { isAuthReady, user, isIntermediateAuthReady } = useAppState();

  const renderChildren = !!user && isAuthReady;
  const renderVerifyOTP = isIntermediateAuthReady && !isAuthReady && !user;
  const authRoute = renderVerifyOTP ? '/verify-otp' : '/login';
  // console.log(`PrivateRoute::isIntermediateAuthReady::${isIntermediateAuthReady}, isAuthReady::${isAuthReady}`);
  // console.log(`PrivateRoute::user::${user}, renderVerifyOTP::${renderVerifyOTP}`);
  // console.log('PrivateRoute::authRoute', authRoute);
  // console.log('PrivateRoute::renderChildren', renderChildren);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        renderChildren ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: authRoute,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
