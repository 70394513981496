import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { UserActivityType } from '../../../types';
import useLogActivities from '../../../state/useLogActivities/useLogActivities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { logUserActivity } = useLogActivities();

  const disconnect = () => {
    room.disconnect();
    logUserActivity(UserActivityType.ClickedEndCall).then();
  }

  return (
    <Button onClick={() => {
      disconnect();
    }} className={clsx(classes.button, props.className)} data-cy-disconnect>
      Disconnect
    </Button>
  );
}
