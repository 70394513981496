import React, { useEffect, useState } from 'react';
import { Button, Grid, Hidden, makeStyles, Theme, Typography } from '@material-ui/core';
import LocalVideoPreview from '../../PreJoinScreens/DeviceSelectionScreen/LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from '../../PreJoinScreens/DeviceSelectionScreen/SettingsMenu/SettingsMenu';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useHistory, useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { TwilioError } from 'twilio-video';
import useSessionContext from '../../../hooks/useSessionContext/useSessionContext';
import { StorageWrapper } from '../../../storage-wrapper';
import { UserActivityType } from '../../../types';
import useLogActivities from '../../../state/useLogActivities/useLogActivities';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'rows-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

export default function CustomerDeviceSelectionScreen() {
  const classes = useStyles();
  const history = useHistory();
  const { isFetching, fetchCustomerSession } = useAppState();
  const { logUserActivity } = useLogActivities();
  const { setCustomer, customer, setAgent, agent } = useSessionContext();
  const { connect, isAcquiringLocalTracks, isConnecting, onError } = useVideoContext();
  const [isFetchingSession, setIsFetchingSession] = useState<boolean>(false);
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const [videoToken, setVideoToken] = useState(undefined);
  const { token: sessionToken } = useParams();
  const defaultRoutePath = '/join';

  const connectCustomerToSession = async () => {
    if (isNil(videoToken)) {
      onError({ message: 'Token is not valid or expired', code: 7001 } as TwilioError);
    } else {
      await connect(videoToken!);
      logUserActivity(UserActivityType.JoinedSession).then();
    }
  };

  useEffect(() => {
    if (isFetchingSession || isNil(sessionToken) || !isNil(videoToken)) {
      return;
    }
    setIsFetchingSession(true);
    fetchCustomerSession(sessionToken)
      .then(({ token, customer, agent }) => {
        setVideoToken(token);
        setCustomer(Object.assign({}, customer));
        setAgent(Object.assign({}, agent));
        StorageWrapper.storeCustomerModel(customer);
        setIsFetchingSession(false);
      })
      .catch(() => {
        onError({ message: 'Token is not valid or expired', code: 7001 } as TwilioError);
        history.push(defaultRoutePath);
      });
  }, [fetchCustomerSession, setCustomer, setAgent, sessionToken, onError, history, isFetchingSession, videoToken]);

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Join {agent?.name}
      </Typography>

      <Grid container justify="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={customer?.name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
            </Hidden>
            <SettingsMenu mobileButtonClass={classes.mobileButton} />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
            <div>
              <Hidden smDown>
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              </Hidden>
            </div>
            <div className={classes.joinButtons}>
              <Button
                variant="contained"
                color="primary"
                data-cy-join-now
                onClick={connectCustomerToSession}
                disabled={disableButtons}
              >
                Connect
              </Button>
              <Button variant="outlined" color="primary" onClick={() => history.push(defaultRoutePath)}>
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
