import React, { useState, useEffect } from 'react';
import CustomerDeviceSelectionScreen from './CustomerDeviceSelectionScreen/CustomerDeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import PreflightTest from './PreflightTest/PreflightTest';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Video from 'twilio-video';

export default function CustomerPreJoinScreens() {
  const { getAudioAndVideoTracks } = useVideoContext();
  const [mediaError, setMediaError] = useState<Error>();


  useEffect(() => {
    getAudioAndVideoTracks()
      .catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
  }, [getAudioAndVideoTracks]);

  const SubContent = (
    <>
      {Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );

  return (
    <IntroContainer subContent={SubContent}>
      <>
        <CustomerDeviceSelectionScreen />
      </>
    </IntroContainer>
  );
}
