import React from 'react';
import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Participant } from 'twilio-video';
import { makeStyles } from '@material-ui/core/styles';

interface DualScreenParticipantProps {
  dualScreenParticipant: Participant;
  videoOnly?: boolean;
}

const useStyles = makeStyles(() => ({
  videoTrack: {
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    margin: '10px',
  },
}));

export default function DualScreenParticipant({ dualScreenParticipant, videoOnly }: DualScreenParticipantProps) {
  const classes = useStyles();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const videoPriority =
    (dualScreenParticipant === selectedParticipant || dualScreenParticipant === screenShareParticipant) &&
    dualScreenParticipant !== localParticipant
      ? 'high'
      : null;
  const isLocalParticipant = dualScreenParticipant === localParticipant;
  return (
    /* audio is disabled for this participant component because this participant's audio
       is already being rendered in the <ParticipantStrip /> component.  */
    <MainParticipantInfo participant={dualScreenParticipant} isDualScreen={true}>
      <ParticipantTracks
        participant={screenShareParticipant || dualScreenParticipant}
        videoOnly={videoOnly}
        enableScreenShare={!!screenShareParticipant}
        videoPriority={videoPriority}
        isLocalParticipant={isLocalParticipant}
        videoClassName={classes.videoTrack}
      />
    </MainParticipantInfo>
  );
}
