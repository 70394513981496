import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useAppState } from '../../state';
import UserMenu from './UserMenu/UserMenu';
import { useLocation } from 'react-router-dom';
import { LiveUIConfig } from '../../apis/conference/conference-types';

const useStyles = (liveUI?: LiveUIConfig) => {
  return makeStyles((theme: Theme) => ({
    background: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgb(40, 42, 43)',
      height: '100%',
    },
    container: {
      position: 'relative',
      flex: '1',
    },
    innerContainer: {
      display: 'flex',
      width: '888px',
      height: '400px',
      borderRadius: '8px',
      boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
      overflow: 'hidden',
      position: 'relative',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        height: 'auto',
        width: 'calc(100% - 40px)',
        margin: 'auto',
        maxWidth: '400px',
      },
    },
    swooshContainer: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // backgroundImage: Swoosh,
      backgroundColor: liveUI?.primaryColor || 'lightGray',
      backgroundSize: 'cover',
      width: '296px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100px',
        backgroundPositionY: '140px',
      },
    },
    logoContainer: {
      position: 'absolute',
      width: '210px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
        width: '90%',
        textAlign: 'initial',
        '& svg': {
          height: '64px',
        },
      },
    },
    brandFavicon: {
      position: 'absolute',
      top: 0,
      left: 0,
      margin: '20px',
      width: '30px',
      height: '30px',
    },
    brandLogo: {
      margin: '20px',
      width: '150px',
    },
    content: {
      background: 'white',
      width: '100%',
      padding: '3em 4em',
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        padding: '2em',
      },
    },
    title: {
      color: 'white',
      margin: '1em 0 0',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        fontSize: '1.1rem',
      },
    },
    subContentContainer: {
      position: 'absolute',
      marginTop: '1em',
      width: '100%',
    },
  }))();
};

interface IntroContainerProps {
  children: React.ReactNode;
  subContent?: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const doNotRenderUserMenuIn = ['/login', '/verify-otp', '/join'];
  const location = useLocation();
  const { user, brandConfig } = useAppState();
  const classes = useStyles(brandConfig.liveUI);

  // function getFaviconEl() {
  //   return document.getElementById("favicon") || { href: ''};
  // }

  // useEffect(() => {
  //   // console.log('IntroContainer::useEffect', brandConfig);
  //   if (!isEmpty(brandConfig)) {
  //     const favicon = getFaviconEl(); // Accessing favicon element
  //     // @ts-ignore
  //     favicon.href = brandConfig.faviconLogo?.url;
  //   }
  // }, [brandConfig]);

  return (
    <div className={classes.background}>
      <img className={classes.brandFavicon} src={brandConfig.faviconLogo?.url} alt="favicon" />
      {user && !doNotRenderUserMenuIn.includes(location.pathname) && <UserMenu />}
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.swooshContainer}>
            <div className={classes.logoContainer}>
              <img className={classes.brandLogo} src={brandConfig.brandWebLogo?.url} alt="favicon" />
              <Typography variant="h6" className={classes.title}>
                Video Room
              </Typography>
            </div>
          </div>
          <div className={classes.content}>{props.children}</div>
        </div>
        {props.subContent && <div className={classes.subContentContainer}>{props.subContent}</div>}
      </div>
    </div>
  );
};

export default IntroContainer;
