import React from 'react';
import useSessionContext from '../../hooks/useSessionContext/useSessionContext';
import CustomerVideoApp from '../../video-apps/CustomerVideoApp';
import AgentVideoApp from '../../video-apps/AgentVideoApp';

export default function ConferenceSessionPage() {
  const { isAgent } = useSessionContext();

  return (
    <>
      { isAgent && <AgentVideoApp /> }
      { !isAgent && <CustomerVideoApp /> }
    </>
  );
}
