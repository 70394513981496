import { Server } from '../../server';
import { ActivityContext } from '../../types';


const logActivity = async (action: string, sessionId: string, userUid: string, context?: ActivityContext): Promise<any> => {
  return await Server.logActivity(action, sessionId, userUid, context);
};

export {
  logActivity
};
