import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { StorageWrapper } from '../../../storage-wrapper';

import { isEmpty, isNil } from 'lodash';
import useSessionContext from '../../../hooks/useSessionContext/useSessionContext';
import { useParams } from 'react-router-dom';
import { TwilioError } from 'twilio-video';
import { UserActivityType } from '../../../types';
import useLogActivities from '../../../state/useLogActivities/useLogActivities';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name?: string;
  setStep: (step: Steps) => void;
  cancelAction: () => void;
}

export default function DeviceSelectionScreen({ name, setStep, cancelAction }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { customer: customerDetails } = useSessionContext();
  const { getToken, isFetching, createRoom, fetchCustomerSession } = useAppState();
  const { logUserActivity } = useLogActivities();
  const { token: sessionToken } = useParams();
  const { connect, isAcquiringLocalTracks, isConnecting, onError } = useVideoContext();
  const { setCustomer } = useSessionContext();
  const [isFetchingSession, setIsFetchingSession] = useState<boolean>(false);
  const [videoToken, setVideoToken] = useState<any>(undefined);
  const isAutomaticConnectionToSession = !isNil(sessionToken) && !isEmpty(sessionToken);
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting || isNil(customerDetails);

  const startButtonTitle = isAutomaticConnectionToSession ? 'Connect' : 'Start';

  useEffect(() => {
    if (isFetchingSession || isNil(sessionToken) || !isNil(videoToken)) {
      return;
    }
    setIsFetchingSession(true);
    fetchCustomerSession(sessionToken)
      .then(async ({ token, customer, agent }) => {
        setCustomer(Object.assign({}, customer));
        setIsFetchingSession(false);
        setVideoToken(token);
      })
      .catch(() => {
        onError({ message: 'Token is not valid or expired', code: 7001 } as TwilioError);
      });
  }, [sessionToken, fetchCustomerSession, isFetchingSession, onError, setCustomer, videoToken]);

  const connectToSession = () => {
    if (videoToken) {
      connect(videoToken).then(() => {
        StorageWrapper.storeCurrentSessionId(sessionToken);
      });
    } else {
      onError({ message: 'Token is not valid or expired', code: 7001 } as TwilioError);
    }
  };

  // useEffect(() => {
  //   if (!isAutomaticConnectionToSession) {
  //     return;
  //   }
  //   if (isFetchingSession || videoToken) {
  //     return;
  //   }
  //   setIsFetchingSession(true);
  //   console.log('FETCH--CUSTOMER--SESSION::token', isFetchingSession);
  //   connectToSession();
  // }, [fetchCustomerSession, setCustomer, setAgent, sessionToken, connect, isAutomaticConnectionToSession]);

  const createConfSession = async () => {
    // console.log('createConfSession', isAutomaticConnectionToSession);
    setVideoToken(undefined);
    if (isAutomaticConnectionToSession) {
      connectToSession();
      logUserActivity(UserActivityType.JoinedSession).then();
    } else {
      const { roomName, sessionId, customer } = await createRoom(customerDetails!);
      // console.log('createConfSession', customer, sessionId);
      setCustomer(customer);
      getToken(sessionId, roomName).then(async token => {
        await connect(token);
        logUserActivity(UserActivityType.StartSession).then();
        StorageWrapper.storeCurrentSessionId(sessionId);
      });
    }
  };

  const title = isAutomaticConnectionToSession ? 'Connecting to a conference' : 'Start a New Meeting';

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        {title}
      </Typography>

      <Grid container justify="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
            </Hidden>
            <SettingsMenu mobileButtonClass={classes.mobileButton} />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
            <div>
              <Hidden smDown>
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              </Hidden>
            </div>
            {
              <div className={classes.joinButtons}>
                <Button variant="outlined" color="primary" onClick={() => cancelAction()}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  data-cy-join-now
                  onClick={createConfSession}
                  disabled={disableButtons}
                >
                  {startButtonTitle}
                </Button>
              </div>
            }
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
