import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import ResendOneTimePasscode from './ResendOneTimePasscode';

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: 'white',
    color: 'rgb(0, 94, 166)',
    borderRadius: '4px',
    border: '2px solid rgb(2, 122, 197)',
    margin: '1.8em 0 0.7em',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.3em 1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  inputFieldContainer: {
    minHeight: '120px',
    width: '100%',
  },
  submitButtonContainer: {
    padding: '15px 0px',
  },
  inputField: {
    width: '100%',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function VerifyOTPPage() {
  const classes = useStyles();
  const { verifyOTP, user, isAuthReady } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [passcode, setPasscode] = useState('');
  const [authError, setAuthError] = useState<Error | null>(null);

  const _verifyOTP = () => {
    setAuthError(null);
    verifyOTP?.(passcode)
      .then(() => {
        history.replace(location?.state?.from || { pathname: '/' });
      })
      .catch(err => {
        setAuthError(err);
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    _verifyOTP();
  };

  useEffect(() => {
    if (user && isAuthReady) {
      history.replace('/');
      return () => {};
    }
  }, [user, history, isAuthReady]);

  return (
    <IntroContainer>
      <Typography variant="h5" className={classes.gutterBottom}>
        Verify OTP
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container justify="space-between">
          <div className={classes.inputFieldContainer}>
            <InputLabel shrink htmlFor="input-email">
              CODE
            </InputLabel>
            <TextField
              id="input-passcode"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
              type="password"
              variant="outlined"
              size="small"
              autoComplete="current-passcode"
              className={classes.inputField}
            />
            <div>
              {authError && (
                <Typography variant="caption" className={classes.errorMessage}>
                  <ErrorOutlineIcon />
                  {authError.message}
                </Typography>
              )}
            </div>
          </div>
        </Grid>
        <Grid container justify="flex-end" className={classes.submitButtonContainer}>
          {<ResendOneTimePasscode />}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!(passcode.length > 1)}
            className={classes.submitButton}
          >
            Verify
          </Button>
        </Grid>
      </form>
    </IntroContainer>
  );
}
